var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pages)?_c('v-layout',[_c('v-flex',{attrs:{"md8":"","offset-md2":""}},[_c('v-list',[_vm._l((_vm.pages),function(p,idx){return [(idx < _vm.pages.length - 1)?_c('v-divider',{key:idx}):_vm._e(),_c('v-list-item',{key:("preview-image-" + (p.id)),staticClass:"page-line"},[_c('v-list-item-avatar',[(p.images && p.images.length > 0)?_c('img',{attrs:{"src":p.images[0].url,"alt":p.images[0].alt}}):_vm._e()]),_c('v-list-item-title',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm._f("shorten")(p.description,30))+" ")]),_c('v-list-item-action',{staticClass:"page-list-item"},[_c('v-checkbox',{attrs:{"input-value":p.public,"color":_vm.$vuetify.breakpoint.xsOnly ? '' : 'primary',"true-value":true,"false-value":false,"label":_vm._f("capitalize")((_vm.$vuetify.breakpoint.xsOnly
                    ? ''
                    : p.public
                    ? _vm.$t('public', [])
                    : _vm.$t('private', [])))},on:{"change":function($event){return _vm.publishPage(p.id, $event)}}}),_c('page-edition-dialog',{attrs:{"state":'edit',"all-activities":_vm.allActivities},on:{"page-saved":_vm.onPageSaved},scopedSlots:_vm._u([{key:"open-btn",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":_vm.$vuetify.breakpoint.xsOnly ? '' : 'primary',"to":{
                      name: 'page-details',
                      params: { page_name: p.name }
                    }}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',_vm._g({attrs:{"small":"","color":_vm.$vuetify.breakpoint.xsOnly ? '' : 'primary'},on:{"click":function($event){return _vm.editPage(p.id)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"small":"","color":_vm.$vuetify.breakpoint.xsOnly ? '' : 'primary'},on:{"click":function($event){$event.stopPropagation();return _vm.deletePage(p.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',{attrs:{"slot":"submitText"},slot:"submitText"},[_vm._v(" "+_vm._s(_vm.$t('save'))+" "+_vm._s(_vm.$t('modifications'))+" ")]),_c('span',{attrs:{"slot":"pageTitle"},slot:"pageTitle"},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" "+_vm._s(_vm.$t('page'))+" : "+_vm._s(p.title)+" ")])])],1)],1)]})],2)],1)],1):_vm._e(),_c('v-layout',[_c('v-flex',{attrs:{"d-flex":""}},[_c('page-edition-dialog',{attrs:{"state":'new',"all-activities":_vm.allActivities},on:{"page-saved":_vm.onPageSaved},scopedSlots:_vm._u([{key:"open-btn",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","color":"success","text":""}},on),[_vm._v(" "+_vm._s(_vm.$t('new'))+" "+_vm._s(_vm.$t('page'))+" ")])]}}])},[_c('span',{attrs:{"slot":"pageTitle"},slot:"pageTitle"},[_vm._v(" "+_vm._s(_vm.$t('new'))+" "+_vm._s(_vm.$t('page'))+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }