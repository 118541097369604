export const CONVERSATION_ADD_MESSAGE = 'CONVERSATION_ADD_MESSAGE'
export const CONVERSATION_SEND_MESSAGE = 'CONVERSATION_SEND_MESSAGE'
export const CONVERSATION_MESSAGE_SENT = 'CONVERSATION_MESSAGE_SENT'
export const NEW_CONVERSATION = 'NEW_CONVERSATION'
export const REMOVE_CONVERSATION = 'REMOVE_CONVERSATION'
export const CONVERSATION_REMOVED = 'CONVERSATION_REMOVED'
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS'
export const GOT_CONVERSATIONS = 'GOT_CONVERSATIONS'
export const CONVERSATION_ERROR = 'CONVERSATION_ERROR'
export const MESSAGES_READ = 'MESSAGES_READ'
