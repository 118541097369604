var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(
      [
        ("/" + (_vm.$i18n.locale)),
        ("/" + (_vm.$i18n.locale) + "/"),
        ("/" + (_vm.$i18n.locale) + "/about"),
        ("/" + (_vm.$i18n.locale) + "/signup"),
        ("/" + (_vm.$i18n.locale) + "/login")
      ].indexOf(_vm.$route.path) >= 0
    )?_c('v-carousel',{staticClass:"background-carousel",attrs:{"height":"100vh","hide-delimiters":"","cycle":"","show-arrows":false}},_vm._l((_vm.backgrounds),function(bg,i){return _c('v-carousel-item',{key:i,staticClass:"carousel-item",attrs:{"src":bg.src}})}),1):_vm._e(),_c('app-nav'),_c('v-main',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),_c('app-snack-bar'),_c('app-loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }