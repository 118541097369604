var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('auth-form',_vm._b({},'auth-form',{
          title: _vm.title,
          buttonMessage: _vm.buttonMessage,
          welcome: _vm.welcome,
          submit: _vm.submit,
          flat: _vm.flat,
          color: _vm.color,
          errors: _vm.errors
        },false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }